import React from "react";
import { SocialIcon } from "react-social-icons";
import TOS from "../assets/RankedMedia_TermsofService_082824.pdf";
import PP from "../assets/Ranked_PrivacyPolicy082824.pdf";

const Socials = {
  instagram: "https://www.instagram.com/ranked_10/",
  twitter: "https://x.com/RankedMediaTech",
  tiktok: "https://www.tiktok.com/@rankedmediatech?_t=8nJYJ8RDVhy&_r=1",
};
// give curl command for local host
// curl -v http://localhost:3000/apple-app-site-association
const Home = () => {
  const infoEmail = "info@ranked.media";
  const creatorSignUpLink =
    "https://technology.us13.list-manage.com/subscribe?u=153a1fba6d45ad02619f3b3b2&id=351db1d899";

  return (
    <div className="App">
      <div className="main-col">
        <img
          alt="ranked logo"
          src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/ranked-heavy-1024-2x.webp?alt=media&token=f0db69e6-5d08-414c-97d3-9d11f72aa902"
          className="ranked-logo"
        />
        <p>
          We connect Brands to thousands of Creators of Color and their 100
          million+ followers.
        </p>
        <a href="https://apps.apple.com/us/app/ranked-for-content-creators/id1364172790">
          <img
            alt="App Store logo"
            src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/appstore.png?alt=media&token=cb3e9dbd-5201-4a70-8dbe-fd265addbcde"
            className="responsive-img app"
          />
        </a>
        <br />
        <br />
        <img
          alt="hand holding phone with Ranked app"
          src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/rankedhand.png?alt=media&token=ce62d216-866d-40ea-850e-3f7f9ce8f19b"
          className="responsive-img large"
        />
        <div className="col">
          <img
            alt="qr code to email about Brand inquiry"
            src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/brand.jpeg?alt=media&token=04db1a17-3dc9-451e-9bce-ab46418725d8"
            className="responsive-img small"
          />
          <p>
            <a href={`mailto:${infoEmail}`}>BRANDS</a>
          </p>
          <img
            alt="qr code to sign up as a Creator"
            src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/creator.jpeg?alt=media&token=df6733e1-41de-4271-b4ce-14e4b4670a72"
            className="responsive-img small"
          />
          <a href={creatorSignUpLink}>
            <p>CREATORS SIGN UP HERE</p>
          </a>
          <img
            alt="qr code to email about a demo app inquiry"
            src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/demo.jpeg?alt=media&token=5d5c543a-4399-4e4f-9d5a-12c657bf00a2"
            className="responsive-img small"
          />
          <p>
            <a href={`mailto:${infoEmail}`}>REQUEST DEMO</a>
          </p>
        </div>
        <video className="responsive-video" controls autoPlay>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/Ranked.mp4?alt=media&token=431b71b4-d623-4850-bdd0-b541a776a941"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <br />
        <img
          alt="various logos"
          src="https://firebasestorage.googleapis.com/v0/b/ranked-production-be470.appspot.com/o/logosevergreen.png?alt=media&token=35aef566-910d-4fd4-aea9-3ce7c494a397"
          className="responsive-img logos"
        />
        <div className="socials-row">
          <SocialIcon network="instagram" url={Socials.instagram} />
          <SocialIcon network="twitter" url={Socials.twitter} />
          <SocialIcon network="tiktok" url={Socials.tiktok} />
        </div>
        <div>
          <p>
            <a href="https://rankedtechnology.blog/2024/07/18/rewarding-equitability/">
              Ranked Blog
            </a>
          </p>
        </div>
        <div className="footer">
          <div>
            <a href={TOS} target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a href={PP} target="_blank" rel="noreferrer">
              Private Policy
            </a>
          </div>
          <p>Ranked Media Tech Inc. 2024</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
